import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Zap, Shield, Crown, CheckCircle2, Activity } from 'lucide-react';

const ServerSelector = ({ servers, activeServer, onServerChange }) => {
  const tiers = [
    {
      name: 'Premium',
      icon: <Crown className="w-4 h-4" />,
      color: 'gold',
      servers: servers.slice(0, 5),
      description: 'High quality servers'
    },
    {
      name: 'Standard',
      icon: <Zap className="w-4 h-4" />,
      color: 'emerald',
      servers: servers.slice(5, 10),
      description: 'Balanced performance'
    },
    {
      name: 'Backup',
      icon: <Shield className="w-4 h-4" />,
      color: 'blue',
      servers: servers.slice(10),
      description: 'Alternative options'
    }
  ];

  return (
    <div className="bg-black/40 backdrop-blur-xl rounded-xl border border-white/10 overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-white/10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Activity className="w-4 h-4 text-gold-500" />
            <h3 className="text-white font-medium">Available Servers</h3>
          </div>
          <div className="flex items-center gap-2">
            <span className="w-2 h-2 rounded-full bg-green-500 animate-pulse" />
            <span className="text-xs text-white/50">{servers.length} Active</span>
          </div>
        </div>
      </div>

      {/* Server Groups */}
      <div className="divide-y divide-white/5">
        {tiers.map((tier) => (
          <div key={tier.name} className="p-3">
            {/* Tier Header */}
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <div className={`text-${tier.color}-500`}>{tier.icon}</div>
                <div>
                  <h4 className={`text-sm font-medium text-${tier.color}-500`}>
                    {tier.name}
                  </h4>
                  <p className="text-xs text-white/40">{tier.description}</p>
                </div>
              </div>
            </div>

            {/* Servers Grid */}
            <div className="grid grid-cols-2 gap-1.5">
              {tier.servers.map((server) => (
                <motion.button
                  key={server.url}
                  onClick={() => onServerChange(server.url)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`relative group p-2.5 rounded-lg transition-all duration-300
                            ${activeServer === server.url 
                              ? `bg-${tier.color}-500/20 border-${tier.color}-500/50` 
                              : 'bg-white/5 border-transparent'
                            }
                            border hover:border-${tier.color}-500/30
                            hover:bg-${tier.color}-500/10`}
                >
                  {/* Server Status Indicator */}
                  <div className="flex items-center gap-2">
                    <div className={`w-1.5 h-1.5 rounded-full 
                                   ${activeServer === server.url
                                     ? `bg-${tier.color}-500`
                                     : 'bg-white/20 group-hover:bg-white/40'
                                   }`} 
                    />
                    <span className={`text-sm font-medium
                                    ${activeServer === server.url
                                      ? `text-${tier.color}-500`
                                      : 'text-white/70 group-hover:text-white'
                                    }`}>
                      {server.server.replace('Server ', '')}
                    </span>
                  </div>

                  {/* Active Indicator */}
                  <AnimatePresence>
                    {activeServer === server.url && (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                        className={`absolute -top-1 -right-1 text-${tier.color}-500`}
                      >
                        <CheckCircle2 className="w-4 h-4" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServerSelector;
