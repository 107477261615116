import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaHome, 
  FaFilm, 
  FaTv, 
  FaFilter, 
  FaSearch, 
  FaUser,
  FaArrowLeft,
  FaTimes,
  FaDragon,
  FaBookmark
} from 'react-icons/fa';
import { getSearchSuggestions } from '../services/search';
import { debounce } from 'lodash';
import { useSwipeable } from 'react-swipeable';

const MobileNavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchState, setSearchState] = useState({
    isOpen: false,
    query: '',
    results: [],
    isLoading: false,
    error: null,
    hasMore: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = useRef(null);
  const overlayRef = useRef(null);

  // Bottom navigation items
  const bottomNavItems = [
    { icon: FaHome, label: 'Home', path: '/home' },
    { icon: FaFilm, label: 'Movies', path: '/movies' },
    { icon: FaTv, label: 'TV', path: '/tv' },
    { icon: FaDragon, label: 'Anime', path: '/anime' },
    { icon: FaBookmark, label: 'Watchlist', path: '/watchlist' },
    { icon: FaFilter, label: 'Filter', path: '/filter' },
  ];

  // Enhanced search handlers with better UX
  const searchHandlers = useSwipeable({
    onSwipedDown: () => setSearchState(prev => ({ ...prev, isOpen: false })),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  // Optimized debounced search
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (query.length < 2) {
        setSearchState(prev => ({ ...prev, results: [], isLoading: false, hasMore: false }));
        return;
      }

      setSearchState(prev => ({ ...prev, isLoading: true }));
      try {
        const results = await getSearchSuggestions(query);
        setSearchState(prev => ({
          ...prev,
          results: results.slice(0, 5),
          hasMore: results.length > 5,
          isLoading: false,
          error: null
        }));
      } catch (error) {
        setSearchState(prev => ({
          ...prev,
          results: [],
          isLoading: false,
          error: 'Search failed. Please try again.',
          hasMore: false
        }));
      }
    }, 300),
    []
  );

  // Handle "View All Results" navigation
  const handleViewAllResults = () => {
    navigate(`/search?q=${encodeURIComponent(searchState.query)}`);
    setSearchState(prev => ({ ...prev, isOpen: false }));
  };

  // Enhanced Search Overlay with better animations
  const SearchOverlay = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="fixed inset-0 bg-black/95 z-50"
      {...searchHandlers}
    >
      <div className="flex flex-col h-full">
        {/* Search Input Area */}
        <motion.div 
          className="p-4 border-b border-gold-500/20 bg-black/40 backdrop-blur-md"
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        >
          <div className="flex items-center gap-3">
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={() => setSearchState(prev => ({ ...prev, isOpen: false }))}
              className="p-2.5 rounded-full hover:bg-gold-500/10 text-gold-500"
            >
              <FaArrowLeft className="w-5 h-5" />
            </motion.button>
            
            <form 
              className="flex-1"
              onSubmit={(e) => {
                e.preventDefault();
                handleViewAllResults();
              }}
            >
              <input
                ref={searchInputRef}
                type="text"
                value={searchState.query}
                onChange={(e) => {
                  const query = e.target.value;
                  setSearchState(prev => ({ ...prev, query }));
                  debouncedSearch(query);
                }}
                placeholder="Search movies, shows..."
                className="w-full bg-white/10 text-gold-500 rounded-xl px-4 py-3
                  border border-gold-500/20 focus:border-gold-500/50
                  focus:ring-2 focus:ring-gold-500/20 focus:outline-none
                  placeholder-gold-500/50 transition-all duration-200"
                autoFocus
              />
            </form>
          </div>
        </motion.div>

        {/* Results Area with smooth animations */}
        <motion.div 
          className="flex-1 overflow-y-auto p-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {searchState.query.length > 0 && (
            <div className="space-y-2">
              {/* Quick Results */}
              {searchState.results.map((item, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="flex items-center gap-3 p-3 bg-white/5 rounded-xl
                    border border-gold-500/10 hover:border-gold-500/30
                    transition-all duration-200 cursor-pointer"
                  onClick={() => {
                    navigate(`/details/${item.media_type}/${item.id}`);
                    setSearchState(prev => ({ ...prev, isOpen: false }));
                  }}
                >
                  <div className="relative w-16 h-20 rounded-lg overflow-hidden">
                    {item.poster_path ? (
                      <img
                        src={`https://image.tmdb.org/t/p/w92${item.poster_path}`}
                        alt={item.title || item.name}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full bg-gold-500/10 flex items-center justify-center">
                        <FaFilm className="text-gold-500/50 text-2xl" />
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-gold-500 font-medium line-clamp-1">
                      {item.title || item.name}
                    </h3>
                    <p className="text-xs text-gold-400/70 capitalize">
                      {item.media_type} • {item.release_date?.split('-')[0] || 'N/A'}
                    </p>
                  </div>
                </motion.div>
              ))}

              {/* View All Results Button */}
              {searchState.hasMore && (
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  onClick={handleViewAllResults}
                  className="w-full mt-4 p-4 bg-gold-500/10 rounded-xl
                    border border-gold-500/20 hover:bg-gold-500/20
                    text-gold-500 font-medium transition-all duration-200"
                >
                  View All Results
                </motion.button>
              )}
            </div>
          )}

          {/* Loading State */}
          {searchState.isLoading && (
            <div className="flex justify-center py-4">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                className="w-6 h-6 border-2 border-gold-500 border-t-transparent rounded-full"
              />
            </div>
          )}
        </motion.div>
      </div>
    </motion.div>
  );

  // Main menu overlay
  const MenuOverlay = () => (
    <motion.div
      initial={{ x: '-100%' }}
      animate={{ x: 0 }}
      exit={{ x: '-100%' }}
      className="fixed inset-0 bg-black/95 z-50 backdrop-blur-md"
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center p-4 border-b border-gold-500/20">
          <h1 className="text-gold-500 text-xl font-bold">Streamify</h1>
          <button 
            onClick={() => setIsMenuOpen(false)}
            className="p-2 text-gold-500"
          >
            <FaTimes />
          </button>
        </div>

        <nav className="flex-1 p-4 space-y-2">
          {bottomNavItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              onClick={() => setIsMenuOpen(false)}
              className="flex items-center gap-4 p-4 rounded-xl text-gold-400 hover:bg-gold-500/10"
            >
              <item.icon className="text-xl" />
              <span>{item.label}</span>
            </Link>
          ))}
        </nav>

        <div className="p-4 border-t border-gold-500/20">
          <Link
            to="/login"
            onClick={() => setIsMenuOpen(false)}
            className="flex items-center justify-center gap-2 w-full bg-gold-500 text-black py-3 rounded-xl font-medium"
          >
            <FaUser />
            Login
          </Link>
        </div>
      </div>
    </motion.div>
  );

  return (
    <>
      {/* Top Bar */}
      <header className="fixed top-0 left-0 right-0 z-40 bg-black/40 backdrop-blur-md border-b border-gold-500/10">
        <div className="flex items-center justify-between p-4">
          <button
            onClick={() => setIsMenuOpen(true)}
            className="p-2 text-gold-500"
          >
            <motion.div className="space-y-1.5">
              <div className="w-6 h-0.5 bg-current" />
              <div className="w-6 h-0.5 bg-current" />
              <div className="w-6 h-0.5 bg-current" />
            </motion.div>
          </button>

          <Link to="/" className="text-gold-500 text-xl font-bold">
            Streamify
          </Link>

          <button
            onClick={() => setSearchState(prev => ({ ...prev, isOpen: true }))}
            className="p-2 text-gold-500"
          >
            <FaSearch />
          </button>
        </div>
      </header>

      {/* Bottom Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 z-40 bg-black/40 backdrop-blur-md border-t border-gold-500/10">
        <div className="flex justify-around items-center px-2 py-1">
          {bottomNavItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className="flex flex-col items-center p-2 text-gold-400"
            >
              <item.icon className="text-xl mb-1" />
              <span className="text-xs">{item.label}</span>
            </Link>
          ))}
        </div>
      </nav>

      {/* Overlays */}
      <AnimatePresence>
        {isMenuOpen && <MenuOverlay />}
        {searchState.isOpen && <SearchOverlay />}
      </AnimatePresence>
    </>
  );
};

export default MobileNavBar;
