import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Play, Check, Grid, List, 
  Calendar, Clock, PlayCircle
} from 'lucide-react';

const EpisodeSelector = ({ seasons, episodes, currentSeason, currentEpisode, onSelect, watchProgress }) => {
  const [viewMode, setViewMode] = useState('list'); // 'grid' or 'list'

  const formatDuration = (minutes) => {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hrs > 0 ? `${hrs}h ` : ''}${mins}m`;
  };

  const handleSeasonChange = (e) => {
    const newSeason = Number(e.target.value);
    // Changed to pass the correct structure
    onSelect({ 
      episode_number: 1,
      season_number: newSeason,
      // Add necessary properties that the parent component might expect
      id: episodes[0]?.id || 0,
      name: episodes[0]?.name || ''
    });
  };

  const isEpisodeWatched = (episode) => {
    const seasonKey = `season${currentSeason}`;
    return watchProgress[seasonKey]?.includes(episode.episode_number) || false;
  };

  return (
    <div className="bg-black/40 backdrop-blur-xl rounded-xl border border-white/10">
      {/* Header */}
      <div className="p-4 border-b border-white/10">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-white font-medium">Episodes</h3>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setViewMode('list')}
              className={`p-1.5 rounded-md transition-colors ${
                viewMode === 'list' ? 'bg-white/10 text-gold-500' : 'text-white/50 hover:text-white'
              }`}
            >
              <List className="w-4 h-4" />
            </button>
            <button
              onClick={() => setViewMode('grid')}
              className={`p-1.5 rounded-md transition-colors ${
                viewMode === 'grid' ? 'bg-white/10 text-gold-500' : 'text-white/50 hover:text-white'
              }`}
            >
              <Grid className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Improved Season Selector with hover effects */}
        <div className="space-y-3">
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between text-xs text-white/50">
              <span>Select Season</span>
              <span>{episodes.length} Episodes</span>
            </div>
            <select
              value={currentSeason}
              onChange={handleSeasonChange}
              className="w-full bg-black/60 text-gold-500 border border-gold-500/10 
                       rounded-lg px-4 py-3 text-sm font-medium
                       hover:border-gold-500/50 focus:border-gold-500 
                       hover:bg-gold-500/5 focus:bg-gold-500/10
                       focus:ring-1 focus:ring-gold-500 focus:outline-none
                        cursor-pointer transition-all duration-200"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23D4AF37'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                backgroundPosition: 'right 1rem center',
                backgroundSize: '1.5em',
                backgroundRepeat: 'no-repeat',
                paddingRight: '2.5rem'
              }}
            >
              {seasons?.map(season => (
                <option
                  key={season.season_number}
                  value={season.season_number}
                  className="relative bg-black text-gold-500 py-3 hover:bg-gold-500/10
                           cursor-pointer transition-colors duration-200"
                >
                  Season {season.season_number}
                  {season.name !== `Season ${season.season_number}` ? ` - ${season.name}` : ''}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center gap-2 px-1">
            <span className="w-1.5 h-1.5 rounded-full bg-gold-500"/>
            <span className="text-xs text-gold-500 font-medium">
              Currently watching Season {currentSeason}
            </span>
          </div>
        </div>
      </div>

      {/* Episodes Container */}
      <div className="max-h-[60vh] overflow-y-auto">
        {viewMode === 'grid' ? (
          <div className="grid grid-cols-2 gap-2 p-2">
            {episodes.map((episode) => {
              const watched = isEpisodeWatched(episode);
              return (
                <motion.button
                  key={episode.id}
                  onClick={() => onSelect(episode)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`relative group aspect-video rounded-lg overflow-hidden
                          ${currentEpisode === episode.episode_number ? 'ring-2 ring-gold-500' : ''}
                          ${watched ? 'bg-gold-500/10' : ''}`}
                >
                  <img
                    src={`https://image.tmdb.org/t/p/w300${episode.still_path}`}
                    alt=""
                    className={`w-full h-full object-cover
                            ${isEpisodeWatched(episode) ? 'opacity-60' : ''}`}
                  />
                  <div className="absolute inset-0 bg-black/60 flex flex-col justify-between p-3">
                    <div className="flex items-center justify-between">
                      <span className="text-sm font-medium text-white">
                        Episode {episode.episode_number}
                      </span>
                      {isEpisodeWatched(episode) && (
                        <div className="flex items-center gap-1">
                          <Check className="w-4 h-4 text-gold-500" />
                          <span className="text-xs text-gold-500">Watched</span>
                        </div>
                      )}
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-white line-clamp-1">
                        {episode.name}
                      </h4>
                      <div className="flex items-center gap-2 mt-1">
                        <PlayCircle className="w-4 h-4 text-gold-500" />
                        <span className="text-xs text-white/70">Watch Now</span>
                      </div>
                    </div>
                  </div>
                </motion.button>
              );
            })}
          </div>
        ) : (
          <div className="space-y-1 p-2">
            {episodes.map((episode) => (
              <motion.button
                key={episode.id}
                onClick={() => onSelect(episode)}
                className={`w-full flex items-center gap-3 p-2 rounded-lg text-left
                          transition-all duration-200
                          ${currentEpisode === episode.episode_number 
                            ? 'bg-gold-500/20 text-white' 
                            : isEpisodeWatched(episode)
                              ? 'bg-gold-500/5 text-white/90'
                              : 'text-white/70 hover:bg-white/5 hover:text-white'}`}
              >
                <div className="shrink-0 w-32 aspect-video rounded-md overflow-hidden">
                  <img
                    src={`https://image.tmdb.org/t/p/w300${episode.still_path}`}
                    alt=""
                    className={`w-full h-full object-cover transition-opacity
                              ${isEpisodeWatched(episode) ? 'opacity-60' : ''}`}
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="text-xs font-medium text-white/50">E{episode.episode_number}</span>
                    <h4 className="text-sm font-medium truncate">{episode.name}</h4>
                    {isEpisodeWatched(episode) && (
                      <div className="flex items-center gap-1">
                        <Check className="w-3 h-3 text-gold-500" />
                        <span className="text-xs text-gold-500">Watched</span>
                      </div>
                    )}
                  </div>
                  <p className="text-xs text-white/50 line-clamp-2 mt-1">
                    {episode.overview || 'No description available.'}
                  </p>
                  <div className="flex items-center gap-3 mt-2">
                    <div className="flex items-center gap-1 text-xs text-white/40">
                      <Clock className="w-3 h-3" />
                      <span>{formatDuration(episode.runtime || 45)}</span>
                    </div>
                    <div className="flex items-center gap-1 text-xs text-white/40">
                      <Calendar className="w-3 h-3" />
                      <span>{new Date(episode.air_date).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
                <Play className={`w-4 h-4 ${isEpisodeWatched(episode) ? 'text-gold-400' : 'text-gold-500'} shrink-0`} />
              </motion.button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EpisodeSelector;
