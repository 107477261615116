import React from 'react';
import { motion } from 'framer-motion';
import { Check, X, AlertCircle } from 'lucide-react';

const Toast = ({ message, type = 'success', onClose }) => {
  const icons = {
    success: <Check className="w-5 h-5 text-green-500" />,
    error: <AlertCircle className="w-5 h-5 text-red-500" />,
    info: <AlertCircle className="w-5 h-5 text-gold-500" />
  };

  const backgrounds = {
    success: 'border-green-500/20',
    error: 'border-red-500/20',
    info: 'border-gold-500/20'
  };

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20, scale: 0.9 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, x: 100, scale: 0.9 }}
      transition={{
        layout: { duration: 0.2 },
        scale: { duration: 0.15 }
      }}
      className={`flex items-center gap-3 min-w-[300px] max-w-md
                bg-zinc-900/95 backdrop-blur-sm border ${backgrounds[type]}
                px-4 py-3 rounded-lg shadow-xl`}
    >
      <span className="flex items-center gap-2 flex-1">
        {icons[type]}
        <span className="text-white text-sm font-medium">{message}</span>
      </span>
      <button 
        onClick={onClose}
        className="text-gray-400 hover:text-white transition-colors p-1"
      >
        <X className="w-4 h-4" />
      </button>
    </motion.div>
  );
};

export default Toast;
