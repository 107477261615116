import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { Github, Heart, Laptop2, LucideTwitter, Mail, TerminalIcon, Twitter, TwitterIcon, X } from 'lucide-react';
import { Briefcase, Layout, Globe, Code, Terminal, Laptop } from 'lucide-react';

export const Footer = () => {
  // Animation variants (keep the previous animation setup)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <motion.footer 
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={containerVariants}
      className="bg-gradient-to-b from-black/40 to-[#0a0a0a] border-t border-gold-500/20 py-16 overflow-hidden"
    >
      <div className="container mx-auto px-6 max-w-6xl">
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-12"
          variants={containerVariants}
        >
          {/* Brand Section */}
          <motion.div 
            className="md:col-span-1"
            variants={itemVariants}
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <h3 className="text-3xl font-bold text-gold-500 tracking-wider mb-6 
              bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-700
              hover:from-gold-600 hover:to-gold-800 transition-all duration-500">
              Streamify
            </h3>
            <p className="text-gold-300 text-sm leading-relaxed mb-6 
              transition-all duration-500 hover:text-gold-200">
              Discover, stream, and immerse yourself in a world of endless entertainment. From blockbuster movies to hidden anime gems, your ultimate streaming companion awaits.
            </p>
            
            {/* Social Icons */}
            <div className="flex space-x-5">
              {[
                { Icon: LucideTwitter, link: 'https://x.com/ae_pluss' },
                { Icon: Laptop2, link: 'https://a-edev.vercel.app' },
                { Icon: Github, link: 'https://github.com/a-edev' },
                { Icon: Mail, link: 'mailto:support@streamify.com' }
              ].map(({ Icon, link }, index) => (
                <motion.a 
                  key={link}
                  href={link}
                  variants={itemVariants}
                  whileHover={{ 
                    scale: 1.2,
                    rotate: [0, -10, 10, 0],
                    transition: { duration: 0.3 }
                  }}
                  whileTap={{ scale: 0.9 }}
                  className="text-gold-400 hover:text-gold-500 transition-all duration-300"
                >
                  <Icon className="text-2xl" />
                </motion.a>
              ))}
            </div>
          </motion.div>
          
          {/* Quick Links */}
          <motion.div 
            className="md:col-span-1"
            variants={itemVariants}
          >
            <h4 className="text-xl font-semibold text-gold-500 mb-6 border-b border-gold-600/60 pb-3">
              Quick Links
            </h4>
            <div className="grid grid-cols-2 gap-4">
              {[
                [
                  { name: 'Home', path: '/home' },
                  { name: 'Movies', path: '/movies' },
                  { name: 'TV Shows', path: '/tv' },
                  {name: 'Anime', path: '/anime'}
                ],
                [
                  { name: 'Filter', path: '/filter' },
                  { name: 'Profile', path: '/profile' },
                  { name: 'About Us', path: '/about' },
                  {name: 'WatchList', path: '/watchlist'},
                  {name: 'Upcoming', path: '/upcoming'}
                ]
              ].map((linkGroup, groupIndex) => (
                <ul key={groupIndex} className="space-y-3">
                  {linkGroup.map((link) => (
                    <motion.li 
                      key={link.name}
                      variants={itemVariants}
                      whileHover={{ 
                        x: 10,
                        transition: { type: "spring", stiffness: 300 }
                      }}
                    >
                      <Link 
                        to={link.path}
                        className="text-gold-300 hover:text-gold-500 transition-colors flex items-center group"
                      >
                        <span className="mr-2 text-gold-600 group-hover:translate-x-1 transition-transform">→</span>
                        {link.name}
                      </Link>
                    </motion.li>
                  ))}
                </ul>
              ))}
            </div>
          </motion.div>
          
          {/* Legal & Contact */}
          <motion.div 
            className="md:col-span-1"
            variants={itemVariants}
          >
            <h4 className="text-xl font-semibold text-gold-500 mb-6 border-b border-gold-600/60 pb-3">
              Legal & Contact
            </h4>
            <ul className="space-y-3">
              {[
                { name: 'Privacy Policy', path: '/privacy' },
                { name: 'Terms of Service', path: '/terms' },
                { name: 'DMCA', path: '/dcma' },
                { name: 'Contact Support', path: '/contact' },
                {name : 'FAQ', path: '/faq'},
              ].map((link) => (
                <motion.li 
                  key={link.name}
                  variants={itemVariants}
                  whileHover={{ 
                    x: 10,
                    transition: { type: "spring", stiffness: 300 }
                  }}
                >
                  <Link 
                    to={link.path}
                    className="text-gold-300 hover:text-gold-500 transition-colors flex items-center group"
                  >
                    <span className="mr-2 text-gold-600 group-hover:translate-x-1 transition-transform">→</span>
                    {link.name}
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </motion.div>
        
        {/* Footer Bottom */}
        <motion.div 
          className="mt-12 pt-8 border-t border-gold-500/20 flex flex-col items-center gap-6"
          variants={itemVariants}
        >
          
          {/* Enhanced Credit Section */}
          <motion.div 
            className="flex items-center justify-center gap-3 p-4 rounded-xl
                      bg-gradient-to-r from-black/40 to-black/20
                      border border-gold-500/10 backdrop-blur-sm
                      hover:border-gold-500/30 transition-all duration-500"
            whileHover={{ scale: 1.02 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <span className="text-gold-400/80">Crafted with</span>
            <motion.div
              animate={{ 
                scale: [1, 1.2, 1],
                rotateZ: [0, 10, -10, 0]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <Heart className="w-5 h-5 text-red-500 fill-red-500 filter drop-shadow-glow" />
            </motion.div>
            <span className="text-gold-400/80">by</span>
            <motion.a
              href="https://github.com/A-EDEV"
              target="_blank"
              rel="noopener noreferrer"
              className="relative group"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span className="font-bold bg-gradient-to-r from-gold-400 to-gold-600 
                            bg-clip-text text-transparent group-hover:from-gold-300 
                            group-hover:to-gold-500 transition-all duration-300">
                A-EDev
              </span>
              <motion.div
                className="absolute -bottom-1 left-0 right-0 h-px bg-gradient-to-r 
                         from-gold-400/0 via-gold-400/80 to-gold-400/0"
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }}
              />
            </motion.a>
          </motion.div>

          {/* Enhanced Badges */}
          <div className="flex flex-wrap justify-center gap-4">
              {[
                { text: 'Secure Streaming', icon: '🔒' },
                { text: 'HD Quality', icon: '✨' },
                { text: 'Multi-device', icon: '📱' }
              ].map((badge) => (
                <motion.span 
                  key={badge.text}
                  whileHover={{ scale: 1.05, y: -2 }}
                  className="flex items-center gap-2 bg-gradient-to-r from-gold-500/5 to-gold-500/10 
                           text-gold-400/90 px-4 py-2 rounded-full text-sm border border-gold-500/10 
                           hover:border-gold-500/20 transition-all duration-300"
                >
                  <span>{badge.icon}</span>
                  {badge.text}
                </motion.span>
              ))}
            </div>

            {/* Copyright */}
            <p className="text-gold-400/80 text-center">
              © {new Date().getFullYear()} Streamify. All Rights Reserved.
              <br />
              
            </p>
        </motion.div>
      </div>
    </motion.footer>
  );
};

export default Footer;

<style jsx>{`
  .drop-shadow-glow {
    filter: drop-shadow(0 0 8px rgba(239, 68, 68, 0.5));
  }
`}</style>