import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaPlay, FaStar } from 'react-icons/fa';

const RecommendedContent = ({ items = [], title = "Recommended" }) => (
  <motion.section
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    className="bg-zinc-900/50 rounded-xl p-8 border border-zinc-800"
  >
    <h2 className="text-2xl font-bold text-white mb-8 flex items-center">
      <span className="text-gold-500 mr-2">|</span> {title}
    </h2>
    
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
      {items.map((item, index) => (
        <motion.div
          key={item.id}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.1 }}
          className="group relative aspect-[2/3]"
        >
          <Link to={`/details/${item.media_type}/${item.id}`}>
            <div className="absolute inset-0 rounded-lg overflow-hidden bg-zinc-800">
              <motion.img
                src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                alt={item.title || item.name}
                className="w-full h-full object-cover"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300" />
            </div>

            <div className="absolute inset-x-0 bottom-0 p-4 translate-y-4 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
              <h3 className="text-white font-semibold text-sm line-clamp-1 mb-1">
                {item.title || item.name}
              </h3>
              <div className="flex items-center justify-between text-xs">
                <div className="flex items-center text-gold-500">
                  <FaStar className="mr-1" />
                  <span>{item.vote_average?.toFixed(1)}</span>
                </div>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="bg-gold-500 p-2 rounded-full"
                >
                  <FaPlay className="text-black text-xs" />
                </motion.div>
              </div>
            </div>
          </Link>
        </motion.div>
      ))}
    </div>
  </motion.section>
);

export default RecommendedContent;
