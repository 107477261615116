import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  HelpCircle, 
  ChevronDown, 
  Info, 
  Settings, 
  PlayCircle,
  Shield
} from 'lucide-react';

const Faq = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  const faqSections = [
    {
      category: 'General',
      icon: <Info />,
      questions: [
        {
          question: 'What is Streamify?',
          answer: 'Streamify is a free streaming platform that provides access to a vast library of movies, TV shows, and anime content. We offer high-quality streaming with personalized recommendations.'
        },
        {
          question: 'Is Streamify available worldwide?',
          answer: 'Yes, Streamify is available globally. However, content availability may vary by region due to licensing agreements.'
        },
        {
          question: 'Do I need to create an account?',
          answer: 'While you can browse content without an account, creating one allows you to create watchlists and receive personalized recommendations.'
        },
        {
          question: 'Is Streamify free?',
          answer: 'Yes, Streamify is completely free to use. We maintain our service through minimal advertisements.'
        }
      ]
    },
    {
      category: 'Content & Streaming',
      icon: <PlayCircle />,
      questions: [
        {
          question: 'What type of content is available?',
          answer: 'We offer a wide variety of content including movies, TV shows, and anime. Our library is regularly updated with new releases.'
        },
        {
          question: 'How often is new content added?',
          answer: 'We add new content weekly, including the latest movies and TV episodes. Check our home page regularly for updates.'
        },
        {
          question: 'Can I watch content offline?',
          answer: 'Currently, offline viewing is not supported as we are a browser-based streaming platform.'
        },
        {
          question: 'What streaming quality is available?',
          answer: 'We offer streaming quality up to 1080p HD, with automatic quality adjustment based on your internet connection.'
        }
      ]
    },
    {
      category: 'Technical Support',
      icon: <Settings />,
      questions: [
        {
          question: 'What devices can I use to watch?',
          answer: 'Streamify is accessible on all major web browsers on computers, mobile devices, and tablets.'
        },
        {
          question: 'How can I report technical issues?',
          answer: 'You can report technical issues through our support page or by using the feedback form. Our team typically responds within 24 hours.'
        },
        {
          question: 'What internet speed do I need?',
          answer: 'We recommend: 3+ Mbps for SD quality, 5+ Mbps for HD streaming. Use our built-in quality settings to adjust based on your connection.'
        }
      ]
    },
    {
      category: 'Privacy & Security',
      icon: <Shield />,
      questions: [
        {
          question: 'How is my data protected?',
          answer: 'We use industry-standard encryption and security measures to protect your personal information and viewing history. Read our privacy policy for more details.'
        },
        {
          question: 'What data do you collect?',
          answer: 'We only collect basic usage data and any optional account information you provide to improve our service. We never share your personal information with third parties.'
        }
      ]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const renderQuestion = (question, answer, index, sectionIndex) => {
    const isExpanded = expandedIndex === `${sectionIndex}-${index}`;

    return (
      <motion.div
        variants={itemVariants}
        className="mb-4"
        key={`${sectionIndex}-${index}`}
      >
        <motion.button
          className={`w-full text-left p-4 rounded-xl flex justify-between items-center
                     ${isExpanded 
                       ? 'bg-gold-500 text-black' 
                       : 'bg-zinc-800/50 text-gold-400 hover:bg-zinc-800'}
                     transition-all duration-300`}
          onClick={() => setExpandedIndex(isExpanded ? null : `${sectionIndex}-${index}`)}
        >
          <span className="flex items-center gap-3">
            <HelpCircle className={`w-5 h-5 ${isExpanded ? 'text-black' : 'text-gold-500'}`} />
            <span className="font-medium">{question}</span>
          </span>
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown className="w-5 h-5" />
          </motion.div>
        </motion.button>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <div className="p-4 bg-zinc-800/30 rounded-b-xl border-t border-gold-500/10">
                <p className="text-gray-300">{answer}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white pt-20 pb-12">
      <div className="container mx-auto px-4 max-w-4xl">
        {/* Header */}
        <motion.div 
          className="text-center mt-8 mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-gold-400 to-gold-600 bg-clip-text text-transparent">
            Frequently Asked Questions
          </h1>
          <p className="text-gray-400">
            Find answers to common questions about Streamify
          </p>
        </motion.div>

        {/* FAQ Sections */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="space-y-8"
        >
          {faqSections.map((section, sectionIndex) => (
            <motion.div
              key={section.category}
              variants={itemVariants}
              className="bg-zinc-900/50 rounded-2xl p-6 backdrop-blur-sm"
            >
              <h2 className="text-xl font-semibold text-gold-500 mb-6 flex items-center gap-3">
                {section.icon}
                {section.category}
              </h2>
              <div className="space-y-4">
                {section.questions.map((qa, index) => 
                  renderQuestion(qa.question, qa.answer, index, sectionIndex)
                )}
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Contact Support Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mt-12 text-center"
        >
          <p className="text-gray-400 mb-4">
            Still have questions? We're here to help!
          </p>
          <button
            onClick={() => navigate('/contact')}
            className="bg-gold-500 text-black px-6 py-3 rounded-xl font-medium
                     hover:bg-gold-600 transition-all duration-300"
          >
            Contact Support
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Faq;
