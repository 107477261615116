const FAVORITES_KEY = 'streamify_favorites';

export const getFavorites = () => {
  const favorites = localStorage.getItem(FAVORITES_KEY);
  return favorites ? JSON.parse(favorites) : [];
};

export const addToFavorites = (item) => {
  const favorites = getFavorites();
  const updatedFavorites = [...favorites, {
    ...item,
    addedAt: new Date().toISOString()
  }];
  localStorage.setItem(FAVORITES_KEY, JSON.stringify(updatedFavorites));
  return updatedFavorites;
};

export const removeFromFavorites = (id) => {
  const favorites = getFavorites();
  const updatedFavorites = favorites.filter(item => item.id !== id);
  localStorage.setItem(FAVORITES_KEY, JSON.stringify(updatedFavorites));
  return updatedFavorites;
};